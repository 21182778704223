/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBkrXi9gHPoBxC7wsaATpX1x0ygq-OHOoI",
  "appId": "1:112490889546:web:b246eb3534da335d4501b3",
  "authDomain": "schooldog-i-toombs-ga.firebaseapp.com",
  "measurementId": "G-MYSQH3FTEG",
  "messagingSenderId": "112490889546",
  "project": "schooldog-i-toombs-ga",
  "projectId": "schooldog-i-toombs-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-toombs-ga.appspot.com"
}
